import React from 'react';
import { Link } from 'gatsby';
import { Seo } from '../../../modules/common/components/seo';
import { DashboardLayout } from '../../../modules/layout/components/dashboard-layout';
import { StaticImage } from 'gatsby-plugin-image';
import { SectionHeader } from '../../../modules/common/components/section-header';

import '../../generic-page.scss';
import { NikkeCharacter } from '../../../modules/nikke/common/components/nikke-character';

const NikkeGuidesGamemodesLiberationPage: React.FC = () => {
  return (
    <DashboardLayout className={'generic-page'} game="nikke">
      <ul className="breadcrumb">
        <li>
          <Link to="/nikke/">NIKKE</Link>
        </li>
        <li className="divider">/</li>
        <li>
          <Link to="/nikke/guides">Guides</Link>
        </li>
        <li className="divider">/</li>
        <li>Liberation</li>
      </ul>
      <div className="page-header">
        <div className="page-icon">
          <StaticImage
            src="../../../images/nikke/categories/category_liberation.png"
            alt="Liberation"
          />
        </div>
        <div className="page-details">
          <h1>Liberation</h1>
          <h2>Guide for the Liberation mode in NIKKE.</h2>
        </div>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297235"></div>
      <div className="page-content">
        <SectionHeader title="Overview" />
        <StaticImage
          src="../../../images/nikke/generic/liberation_1.webp"
          alt="Liberation"
        />
        <p>
          The Liberation mode was released with the 12th January 2023 update.
          The mode allows you to obtain <strong>SSR characters for free</strong>
          , but there are a few important things you need to know:
        </p>
        <ul>
          <li>
            There are 3 characters you can obtain: Guilty, Sin and Quency,
          </li>
          <ul>
            <li>
              You can get those characters only from the Liberation mode - they
              don't appear in banners or shards,
            </li>
          </ul>
          <li>
            After picking a character you want to free, while you can change her
            to another, it will cost you some points and time (unless you reset
            your choice at very specific moments - more about that later),
          </li>
          <li>It takes roughly 60 days to unlock one character,</li>
          <li>
            After you unlock a character, you can buy her Spare Bodies in Union
            Shop.
          </li>
        </ul>
        <SectionHeader title="Liberation" />
        <StaticImage
          src="../../../images/nikke/generic/liberation_0.jpg"
          alt="Liberation"
        />
        <p>
          A lot of players are having issues finding the Liberation mode and you
          can do it in the NIKKE screen - there is a new button in the top-left
          corner. You can see it on the image above.
        </p>
        <p>
          Once you press the button for the first time, you will be welcomed by
          this screen:
        </p>
        <StaticImage
          src="../../../images/nikke/generic/liberation_2.jpg"
          alt="Liberation"
        />
        <p>
          Here, you will have to choose one of the 3 characters - either Sin,
          Guilty or Quency - as the target that you want to obtain. Honestly, at
          this point you can pick anyone, because you will start at 0 points
          anyway, so you can change it on the next screen without any penalty.
        </p>
        <p>
          Once you pick someone, this screen will appear and that's the default
          screen for Liberation mode you will see from now on when you return to
          it.
        </p>
        <StaticImage
          src="../../../images/nikke/generic/liberation_4.webp"
          alt="Liberation"
        />
        <p>Here, the screen is split into 3 parts:</p>
        <ul>
          <li>The top part shows the character you want to free,</li>
          <li>
            The middle part shows the quests you have to do to obtain points,
          </li>
          <li>The bottom part shows your progress.</li>
        </ul>
        <h5>Obtaining points</h5>
        <p>
          Daily, 3 random quests will become available here and they're
          basically daily quests with small twists here and there. Once you
          complete one, you just have to press on the tile to obtain the points.
          The quests that can appear here have random rarities that affect how
          many points you will obtain for clearing the mission.
        </p>
        <p>
          After completing a quest, you can{' '}
          <strong>
            refresh it for 50 Gems and then you can obtain the points again
          </strong>
          , but only up to 6 times a day (so daily you can do 3 free + 3 paid
          quests). This means that spenders will be able to drastically decrease
          the time needed to obtain a character.
        </p>
        <p>
          While we will need a few days to check how the rarity of the quests is
          determined, if we assume that a F2P player will just do 3 free quests
          a day (10+20+30, so one quest of each rarity - which is what should
          happen on average),{' '}
          <strong>he will need roughly 60 days to unlock a character</strong>{' '}
          (since 3600 points are required to obtain it).
        </p>
        <p>
          Spenders who want to use 150 Gems a day can decrease the amount of
          time needed by half or even more. Considering the low Gem income, we
          advise F2P players to avoid refreshing the quests.
        </p>
        <h5>Resetting the choice</h5>
        <p>
          The bottom progress bar is split into 6 stages. If you reset your
          choice, you will be 'taken' back to the start of the stage you're
          currently in. This is why we suggest to change the character of your
          choice when you reach a stage, so you won't lose any points.
        </p>
        <SectionHeader title="Available characters" />
        <p>
          Currently in the Liberation mode you can obtain one of three
          characters and it looks{' '}
          <strong>like Guilty is the best out of them.</strong>
        </p>
        <h5>Sin</h5>
        <StaticImage
          src="../../../images/nikke/generic/liberation_sin.webp"
          alt="Liberation"
        />
        <p>
          Sin is Burst II Defender who wields an Assault Rifle. Similar to
          Ludmilla, she has a screen-wide Taunt which is both good and bad. Good
          because it allows you to save your team, and bad as when you fight
          under heavy power deficit this is a death sentence.
        </p>
        <p>You can check her full review here:</p>
        <div className="employee-container for-nikke">
          <NikkeCharacter mode="icon" slug="sin" enablePopover />
        </div>
        <h5>Guilty</h5>
        <StaticImage
          src="../../../images/nikke/generic/liberation_guilty.webp"
          alt="Liberation"
        />
        <p>
          Guilty is Burst II Attacker who wields a Shotgun. She has a unique
          mechanic that allows her to duplicate part of the ATK of the highest
          ATK ally, which massively increases her damage output, especially when
          you pair her with a character that has a lot of ATK.
        </p>
        <p>You can check her full review here:</p>
        <div className="employee-container for-nikke">
          <NikkeCharacter mode="icon" slug="guilty" enablePopover />
        </div>
        <h5>Quency</h5>
        <StaticImage
          src="../../../images/nikke/generic/liberation_quency.webp"
          alt="Liberation"
        />
        <p>
          Quency is Burst II Supporter who wields a pair of SMGs. She can
          increase ATK of 2 allies on a pretty low cooldown, but at the same
          time the buff gives just 16% ATK at max level. On top of that her
          Burst can further increase Critical Damage of 2 allies and also
          increase their Max HP (which is basically a heal).
        </p>
        <p>You can check her full review here:</p>
        <div className="employee-container for-nikke">
          <NikkeCharacter mode="icon" slug="quency" enablePopover />
        </div>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297247"></div>
    </DashboardLayout>
  );
};

export default NikkeGuidesGamemodesLiberationPage;

export const Head: React.FC = () => (
  <Seo
    title="Liberation | NIKKE | Prydwen Institute"
    description="Guide for the Liberation mode in Goddess of Victory: NIKKE."
  />
);
